import React from 'react';
import Helmet from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

type MetaProps = JSX.IntrinsicElements['meta'];

type HtmlProps = JSX.IntrinsicElements['html'] & {
  [key: string]: string;
};

interface Props {
  description?: string;
  lang?: HtmlProps | any;
  meta?: MetaProps[] | any;
  title: string;
}

export const Seo: React.FC<Props> = ({
  description,
  lang,
  meta,
  title,
}: Props) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={lang}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
    />
  );
};

Seo.defaultProps = {
  description: ``,
  lang: `en`,
  meta: [],
};
